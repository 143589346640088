import React from "react"
import bandWave from "../assets/images/bg/banner4-wave1.svg"
import waterMark2 from "../assets/images/bg/watermark2-bg.png"

const Index = () => {
  return (
    <>
      <>
        <div className="banner-section4" style={{ minHeight: "60vh" }}>
          <img src={bandWave} className="banner4-wave1 img-fluid" alt="Wave" />
          <img
            src={waterMark2}
            className="banner4-wave2 img-fluid"
            alt="Wave 1"
          />

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <div
                  className="section-title3 primary4 text-cener"
                  style={{ marginBottom: "12px" }}
                >
                  <span>-Get In Touch-</span>
                  <p>
                    To get in touch, please email us at
                    spacottagepublishing@gmail.com and we will get back to you
                    as soon as possible. Thank you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {!submitted && (
          <div
            className="team-section2 pb-120 position-relative overflow-hidden"
            id="classicsCollection"
          >
            <img src={waterMark3} className="watermark1-bg" alt="image" />
            <div className="container">
              <div className="row justify-content-center">
                <div
                  class="form-section pt-120 pb-120"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <div class="container">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-6">
                        <div class="section-title primary4">
                          <p class="para">
                            * 'SPA Cottage Publishing will reopen for
                            submissions in the future, please check in with our
                            website for the next unsolicited submissions dates.
                            Thank You.' Pamela Brown, Senior Editor, SCP.,
                          </p>
                          {valError && <p>Please fill all required fields</p>}
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-8">
                        <form
                          data-netlify="true"
                          name="contact-SPA"
                          // onSubmit={handleSubmit}
                          method="POST"
                          data-netlify-honeypot="bot-field"
                        >
                          <input
                            type="hidden"
                            name="form-name"
                            value="contact-SPA"
                          />
                          <div class="row g-4">
                            <div class="col-lg-6">
                              <div class="form-inner">
                                <input
                                  type="text"
                                  name="fname"
                                  placeholder="Your Name: *"
                                  onChange={e => {
                                    onChangeHandler("name", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-inner">
                                <input
                                  type="email"
                                  name="lname"
                                  placeholder="Your E-mail:"
                                  onChange={e => {
                                    onChangeHandler("email", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-inner">
                                <input
                                  type="text"
                                  name="number"
                                  placeholder="Phone Number:"
                                  onChange={e => {
                                    onChangeHandler("number", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-inner">
                                <input
                                  type="text"
                                  name="subject"
                                  placeholder="Subject:"
                                  onChange={e => {
                                    onChangeHandler("subject", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-inner">
                                <textarea
                                  name="message"
                                  cols="30"
                                  rows="6"
                                  placeholder="Write A Question "
                                  onChange={e => {
                                    onChangeHandler("message", e.target.value)
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div
                              class="col-lg-12 text-center"
                              onClick={() => onSubmitt()}
                            >
                              <input
                                type="submit"
                                value="Send Now"
                                class="eg-btn btn--submit"
                                disabled={submitted}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </>
      {/* )} */}
    </>
  )
}

export default Index
