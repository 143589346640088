import React from "react"
import { Layout } from "../components/common"
import Contact from "../components/Contact"
import { Helmet } from "react-helmet"
const classics = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <Contact />
    </Layout>
  )
}

export default classics
